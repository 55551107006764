import React, {useMemo} from 'react'
import { useTable, useSortBy, useBlockLayout} from 'react-table'
import { useSticky } from 'react-table-sticky'
import theData from './banque.json'
import { COLUMNS as theColumns } from './banque-columns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faSort} from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap-grid.css"
import * as Bs from "react-bootstrap"
import './table.css'
import { Styles } from './TableStyles'


function Banque(props) {

  let rowArray = []
  let accessorsArray = []

  if(props.row){
    rowArray = props.row
  }

  if(props.accessors){
    accessorsArray = props.accessors
  }


  console.log('accessorsprops', props.accessors)


  function checkFilter(line){
    return rowArray.includes(line.ID)
  }

///// Virements Sortants /////

  function checkColFilter(col){

    ///// Cartes Bancaires /////
    if(props.id === 'postTable' && props.what === 'cartesbancaires'){
      return col.post === 'yes'
      && col.accessor === 'BanqueLogo'
      || col.accessor === 'BanqueCarte'
      || col.accessor === 'TarifCarte'
      || col.accessor === 'OffrePromo'
      || col.accessor === 'BanqueWebsite'
    }

    else if(props.id === 'mobileTable' && props.what === 'cartesbancaires'){
      return col.accessor === 'BanqueCarte'
      // || col.accessor === 'TarifCarte'
      // || col.accessor === 'OffrePromo'
      | col.accessor === 'BanqueWebsite'
    }

   ///// Virement Sortants /////
    else if(props.id === 'postTable' && props.what === 'virementSortants'){
      console.log('its it')
      return (col.post === 'yes'
      && col.accessor === 'BanqueCarte'
      || col.accessor === 'VirementSortantFrance'
      || col.accessor === 'VirementSortantZoneEuro'
      || col.accessor === 'VirementSortantHorsZoneEuro'
      || col.accessor === 'VirementSortantsHorsZoneEuroTaux'
      )
    } else if(props.id === 'mobileTable' && props.what === 'virementSortants'){
      return col.accessor === 'BanqueCarte'
      // || col.accessor === 'VirementSortantFrance'
      // || col.accessor === 'VirementSortantZoneEuro'
      || col.accessor === 'VirementSortantHorsZoneEuro'
      // || col.accessor === 'VirementSortantsHorsZoneEuroTaux'
    }

    ///// Virement Entrants /////
    else if(props.id === 'postTable' && props.what === 'virementEntrants'){
      return col.post === 'yes'
      && col.accessor === 'BanqueCarte'
      || col.accessor === 'VirementRentrantFrance'
      || col.accessor === 'VirementRentrantZoneEuro'
      || col.accessor === 'VirementRentrantHorsZoneEuro'
      || col.accessor === 'VirementRentrantHorsZoneEuroTaux'
    }

    else if(props.id === 'mobileTable' && props.what === 'virementEntrants'){
      return col.accessor === 'BanqueCarte'
      || col.accessor === 'VirementRentrantFrance'
      // || col.accessor === 'VirementRentrantZoneEuro'
      // || col.accessor === 'VirementRentrantHorsZoneEuro'
      // || col.accessor === 'VirementRentrantHorsZoneEuroTaux'
    }

////// Decouverts /////
    else if(props.id === 'postTable' && props.what === 'decouverts'){
      return col.post === 'yes'
      && col.accessor === 'BanqueCarte'
      || col.accessor === 'InteretsDecouvertAuthorise'
      || col.accessor === 'InteretsDecouvertAuthoriseTAEG'
      || col.accessor === 'InteretsDecouvertNonAuthorise'
      // || col.accessor === 'InteretsDecouvertNonAuthoriseTAEG'
      // || col.accessor === 'CommissionDintervention'
    }

    else if(props.id === 'mobileTable' && props.what === 'decouverts'){
      return col.accessor === 'BanqueCarte'
      || col.accessor === 'InteretsDecouvertAuthorise'
      // || col.accessor === 'InteretsDecouvertAuthoriseTAEG'
      // || col.accessor === 'InteretsDecouvertNonAuthorise'
      // || col.accessor === 'InteretsDecouvertNonAuthoriseTAEG'
      // || col.accessor === 'CommissionDintervention'
    }

////// Irrégularités

else if(props.id === 'postTable' && props.what === 'irregularites'){
  return col.post === 'yes'
  && col.accessor === 'BanqueCarte'
  || col.accessor === 'RejetDunVirement'
  || col.accessor === 'RejetDunPrelevement'
  || col.accessor === 'OppositionDunPrelevement'
  || col.accessor === 'OppositionDunCheque'
  || col.accessor === 'ChequeRejetePourDfautDeProvision'
}

else if(props.id === 'mobileTable' && props.what === 'irregularites'){
  return col.accessor === 'RejetDunVirement'
  || col.accessor === 'RejetDunPrelevement'
  // || col.accessor === 'OppositionDunPrelevement'
  // || col.accessor === 'OppositionDunCheque'
  // || col.accessor === 'ChequeRejetePourDfautDeProvision'
}


////// Retraits

else if(props.id === 'postTable' && props.what === 'retraits'){
  return col.post === 'yes'
  && col.accessor === 'BanqueCarte'
  || col.accessor === 'Retraits UE'
  || col.accessor === 'RetraitsHorsUE'
  || col.accessor === 'FraisChangeVisaMastercard'
  || col.accessor === 'PaiementsUE'
  // || col.accessor === 'PaiementsHorsUE'
}

else if(props.id === 'mobileTable' && props.what === 'retraits'){
  return col.accessor === 'RetraitsHorsUE'
  || col.accessor === 'FraisChangeVisaMastercard'
  // || col.accessor === 'PaiementsUE'
  // || col.accessor === 'PaiementsHorsUE'
}

////// Assurances

else if(props.id === 'postTable' && props.what === 'assurances'){
  return col.post === 'yes'
  && col.accessor === 'BanqueCarte'
  || col.accessor === 'AssistanceRapatriment'
  || col.accessor === 'LimiteAssistanceRapatriment'
  || col.accessor === 'AssuranceVoyages'
  || col.accessor === 'GarantieVehiculeDeLocation'
}

else if(props.id === 'mobileTable' && props.what === 'assurances'){
  return col.accessor === 'AssistanceRapatriment'
  // || col.accessor === 'LimiteAssistanceRapatriment'
  || col.accessor === 'AssuranceVoyages'
  // || col.accessor === 'GarantieVehiculeDeLocation'
}

////// Comparison



    else return col.wide === 'yes'
  }

  console.log('the Data', theData.filter(checkFilter))

  const newData = theData.filter(checkFilter)
  const newColumns = theColumns.filter(checkColFilter)
  
  const columns = useMemo(()=> newColumns,[])
  const dataQonto = useMemo(() => newData, [])


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
    columns: columns,
    data: dataQonto,
    initialState: {
      sortBy: [
          {
              id: 'Tarifs',
              desc: false
          }
      ]
  }
  }, 
  useSortBy,
  useBlockLayout,
  useSticky)

  console.group('columns', columns)

  return (
    <>


    <Bs.Container style={{marginTop: 20, maxWidth: 1280, marginLeft: -10, marginRight: -10}}>

<Styles id={props.id}>
      <div {...getTableProps()} className="table sticky" >
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div 
                {...column.getHeaderProps(column.getSortByToggleProps())}
                
                {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  <span style={{cursor: 'pointer'}}>
                  {column.isSorted ? (column.isSortedDesc ?  <FontAwesomeIcon icon={faSortDown} style={{marginLeft: 10}}/>: <FontAwesomeIcon icon={faSortUp} style={{marginLeft: 10}}/>): <FontAwesomeIcon icon={faSort} style={{marginLeft: 10}}/> }
                </span>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className="body" style={{fontSize: 18, color: 'black'}}>
        
          {rows.map((row) => {
            // if(row.cells[0].value.split(',')[0] === 'Qonto'){

              // console.log('row value', row.getRowProps())
            // }
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr" 
              >
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        
      </div>
    </Styles>
    </Bs.Container>
    </>
  )
}

export default Banque
